import React, { useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Input, InputNumber, Form, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import CREATE_INVENTORY_ITEM_QUANTITY_UPDATE from '../../../graphql/inventoryItems/createInventoryItemQuantityUpdate';
import GET_INVENTORY_ITEM from "../../../graphql/inventoryItems/getInventoryItem";
import GET_LOCATION_INVENTORIES_ITEMS from "../../../graphql/inventoryItems/getLocationInventoriesItems";

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const InventoryItemQuantityUpdateModal = (props) => {
  const { inventoryItemId, locationId } = props;
  const [openModal, setOpenModal] = useState(false);
  const [currentQuantityCountedDate, setCurrentQuantityCountedDate] = useState(null);
  const [currentQuantityCounted, setCurrentQuantityCounted] = useState(0);
  const [upcCounted, setUpcCounted] = useState('');
  const [quantityAddedDate, setQuantityAddedDate] = useState(null);
  const [quantityAdded, setQuantityAdded] = useState(0);
  const [upcAdded, setUpcAdded] = useState('');
  const [stockoutDate, setStockoutDate] = useState(null);

  const resetFormStates = () => {
    setCurrentQuantityCountedDate(null);
    setCurrentQuantityCounted(0);
    setUpcCounted('');
    setQuantityAddedDate(null);
    setQuantityAdded(0);
    setUpcAdded('');
    setStockoutDate(null);
  }

  const [
    doCreateInventoryItemQuantityUpdate,
    {
      // data: createInventoryItemQuantityUpdateData,
      loading: createInventoryItemQuantityUpdateLoading,
      error: createInventoryItemQuantityUpdateError,
    },
  ] = useMutation(CREATE_INVENTORY_ITEM_QUANTITY_UPDATE, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_LOCATION_INVENTORIES_ITEMS, variables: { locationId } }],
  });

  return (
    <div>
      <Button onClick={() => setOpenModal(true)} style={{ margin: '.25rem' }}>
        <PlusCircleOutlined />
        {/* Update Quantities */}
      </Button>
      <Modal
        visible={openModal}
        destroyOnClose
        title="Inventory Item Quantity Update"
        okText="Submit"
        confirmLoading={createInventoryItemQuantityUpdateLoading}
        onCancel={() => setOpenModal(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doCreateInventoryItemQuantityUpdate({
            variables: {
              inventoryItemId,
              currentQuantityCountedDate,
              currentQuantityCounted,
              upcCounted,
              quantityAddedDate,
              quantityAdded,
              upcAdded,
              stockoutDate,
            },
          });
          resetFormStates();
          setOpenModal(false);
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Current Quantity">
            <InputNumber
              required
              min={0}
              max={1000000000000}
              value={currentQuantityCounted}
              onChange={value => setCurrentQuantityCounted(value)}
            />
          </Form.Item>
          <Form.Item label="Date Current Quantity was Recorded">
            <DatePicker
              required
              showTime
              placeholder="Select Time"
              onChange={value => setCurrentQuantityCountedDate(value)}
            />
          </Form.Item>
          <Form.Item label="UPC Counted">
            <Input
              placeholder="UPC of Current Quantity"
              value={upcCounted}
              onChange={event => setUpcCounted(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Quantity Recently Added">
            <InputNumber
              required
              min={0}
              max={1000000000000}
              value={quantityAdded}
              onChange={value => setQuantityAdded(value)}
            />
          </Form.Item>
          <Form.Item label="Date Items were Added">
            <DatePicker
              required
              showTime
              placeholder="Select Time"
              onChange={value => setQuantityAddedDate(value)}
            />
          </Form.Item>
          <Form.Item label="UPC Added">
            <Input
              placeholder="UPC of Recently Added Items"
              value={upcAdded}
              onChange={event => setUpcAdded(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Estimated Out of Stock or Expiration Date">
            <DatePicker
              required
              showTime
              placeholder="Select Time"
              onChange={value => setStockoutDate(value)}
            />
          </Form.Item>
          {createInventoryItemQuantityUpdateError && (
            <Alert
              message="There was an API error!"
              description={
                createInventoryItemQuantityUpdateError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

InventoryItemQuantityUpdateModal.propTypes = {
  inventoryItemId: PropTypes.string.isRequired,
};

export default InventoryItemQuantityUpdateModal;
