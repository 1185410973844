import { gql } from 'apollo-boost';

const GET_USER_ONBOARDING_STATUS = gql`
  query getUserOnboardingStatus($_id: String!) {
    getUserOnboardingStatus(_id: $_id) {
      locations
      inventories
      inventoryItems
      inventoryItemCounts
    }
  }
`;

export default GET_USER_ONBOARDING_STATUS;
