import { gql } from 'apollo-boost';

const GET_CURRENT_USER = gql`
  query getCurrentUser($_id: String!) {
    getCurrentUser(_id: $_id) {
      _id
      email
      username
      name
      mobilePhone
      smsAlerts
    }
  }
`;

export default GET_CURRENT_USER;
