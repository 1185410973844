import { gql } from 'apollo-boost';

const GET_USER_LOCATIONS = gql`
  query getUserLocations($ownerId: String!) {
    getUserLocations(ownerId: $ownerId) {
      _id
      deactivated
      ownerId
      name
      description
      mailingAddress {
        address1
        address2
        city
        stateProvince
        postalCode
        gpsCoordinates
      }
    }
  }
`;

export default GET_USER_LOCATIONS;
