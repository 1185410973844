import React from 'react';
import { Select } from 'antd';
import orderBy from 'lodash/orderBy';
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import GET_USER_LOCATIONS from '../../graphql/locations/getUserLocations';

const { Option } = Select;

/*
  eslint
  no-undef: 0
*/

const getUserLocationList = (list) => {
  const sortedLocations = orderBy(list, ['name'], ['asc']);
  return sortedLocations.map(location => (
    <Option key={location._id} value={location._id} title={location.name}>{location.name}</Option>
  ));
};

const UserLocationsDropdown = (props) => {
  const { locationId, setLocationId, setInventoryId } = props;

  const { loading, error, data } = useQuery(GET_USER_LOCATIONS,
    {
      variables: { ownerId: Cookies.get('userId') },
      fetchPolicy: 'cache-and-network',
    });

  if (error) return <div>{error}</div>;

  if (data && data.getUserLocations.length < 1) return <div>You have no Locations!</div>;
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      placeholder="Location"
      notFoundContent="No Locations Found"
      value={locationId}
      onChange={(value) => { setLocationId(value); try { setInventoryId(null); } catch (e) {} }}
      loading={loading}
    >
      {data && getUserLocationList(data.getUserLocations)}
    </Select>
  );
};

UserLocationsDropdown.propTypes = {
  locationId: PropTypes.string.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setInventoryId: PropTypes.func,
}

export default UserLocationsDropdown;
