import React  from 'react';
import { withRouter } from 'react-router';
import SiteLayout from '../components/core/SiteLayout';
import InventoriesList from '../components/inventories/InventoriesList';

const Inventories = () => (
    <SiteLayout isLoggedIn>
      <div className="flex-centered-stack">
        <div className="framing-container-lg">
          <InventoriesList />
        </div>
      </div>
    </SiteLayout>
);

export default withRouter(Inventories);
