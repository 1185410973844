import React from 'react';
import SiteLayout from '../components/core/SiteLayout';
import LocationsList from '../components/locations/LocationsList';

const Locations = () => (
  <SiteLayout isLoggedIn>
    <div className="flex-centered-stack">
      <div className="framing-container-lg">
        <LocationsList />
      </div>
    </div>
  </SiteLayout>
);

export default Locations;
