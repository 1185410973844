import { gql } from 'apollo-boost';

const GET_USER_INVENTORIES = gql`
  query getUserInventories($ownerId: String!) {
    getUserInventories(ownerId: $ownerId) {
      _id
      deactivated
      name
      description
      locationId
    }
  }
`;

export default GET_USER_INVENTORIES;
