import React, { useState } from 'react';
import { FireOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { Input, Button, Spin, Alert, Form } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import SIGNUP from '../../graphql/userAccount/signup';
import * as logo from '../../assets/logo.png';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

// const { getFieldDecorator } = Form.Item;

const SignUpForm = (props) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [betaAccessCode, setBetaAccessCode] = useState('');
  const [signupDisabled, setSignupDisabled] = useState(true);

  const [
    doSignup,
    {
      // data,
      loading: signupLoading,
      error: signupError,
    },
  ] = useMutation(SIGNUP, {
    onCompleted: ({ signup }) => {
      Cookies.set('token', signup.token, { expires: 7});
      Cookies.set('userId', signup._id, { expires: 7 });
      props.history.push('/account');
    },
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '100%', maxWidth: '480px', padding: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="logo" style={{ maxHeight: '150px', marginBottom: '2rem' }} />
        </div>
        <Form
          onFinish={async (event) => {
            await doSignup({
              variables: {
                email,
                username,
                password,
                name,
                mobilePhone,
              },
            });
          }}
          className="signup-form"
        >
          <Form.Item validateStatus={!name && 'warning'}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item validateStatus={!email && 'warning'}>
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Form.Item>
          <Form.Item validateStatus={!username && 'warning'}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              value={username}
              onChange={event => setUsername(event.target.value)}
            />
          </Form.Item>
          <Form.Item validateStatus={!password && 'warning'}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Mobile Phone Number"
              value={mobilePhone}
              onChange={event => setMobilePhone(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={
              (!betaAccessCode || betaAccessCode !== 'QUINLIST') && 'warning'
            }
          >
            <Input
              prefix={<FireOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Beta Access Code"
              value={betaAccessCode}
              onChange={(event) => {
                setBetaAccessCode(event.target.value);
                if (event.target.value === 'QUINLIST') {
                  setSignupDisabled(false);
                } else {
                  setSignupDisabled(true);
                }
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button block aria-disabled={signupDisabled} disabled={signupDisabled} type="primary" htmlType="submit" className="signup-form-button" loading={signupLoading}>
              Sign Up
            </Button>
            <div>
              Already have an acccount?&nbsp;
              <Link to="/login">Login</Link>
            </div>
          </Form.Item>
          {signupError && (
            <Alert
              message="There was an API error!"
              description={
                signupError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </div>
  );
};

SignUpForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(SignUpForm);
