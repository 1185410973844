import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InventoryItemQuantityUpdateModal from './InventoryItemQuantityUpdateModal';
import UpdateInventoryItemModal from '../UpdateInventoryItemModal';

const InventoryItemButtons = (props) => {
  const { _id, inventoryId, locationId } = props;
  const [inventoryItemModalOpen, setInventoryItemModalOpen] = useState(false);
  const [inventoryQuantityUpdateModalOpen, setInventoryQuantityUpdateModalOpen] = useState(false);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
      <InventoryItemQuantityUpdateModal
        inventoryItemId={_id}
        locationId={locationId}
        modalOpen={inventoryQuantityUpdateModalOpen}
        openModal={setInventoryQuantityUpdateModalOpen}
      />
      <UpdateInventoryItemModal
        _id={_id}
        inventoryId={inventoryId}
        locationId={locationId}
        modalOpen={inventoryItemModalOpen}
        openModal={setInventoryItemModalOpen}
      />
    </div>
  );
};

InventoryItemButtons.propTypes = {
  _id: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  inventoryId: PropTypes.string.isRequired,
};

export default InventoryItemButtons;
