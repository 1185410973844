import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { Button, Switch, Modal, Input, Form, Alert, Spin } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import StateProvinceDropdown from '../core/StateProvinceDropdown';
import GET_LOCATION from '../../graphql/locations/getLocation';
import GET_USER_LOCATIONS from '../../graphql/locations/getUserLocations';
import UPDATE_LOCATION from '../../graphql/locations/updateLocation';

/*
  eslint
  no-undef: 0
  react/no-array-index-key: 0
*/

const UpdateLocationModal = (props) => {
  const { _id } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState(undefined);
  const [postalCode, setPostalCode] = useState('');
  const [gpsCoordinates, setGPSCoordinates] = useState('');
  const currentUserId = Cookies.get('userId');

  const resetFormStates = () => {
    setDeactivated(false);
    setName(undefined);
    setDescription('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setStateProvince(undefined);
    setPostalCode('');
    setGPSCoordinates('');
  }

  const [
    doUpdateLocation,
    {
      // data: updateLocationData,
      loading: updateLocationLoading,
      error: updateLocationError,
    },
  ] = useMutation(UPDATE_LOCATION, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_USER_LOCATIONS, variables: { ownerId: currentUserId } }],
  });

  const [
    doGetLocation, {
      data: getLocationData,
      loading: getLocationLoading,
      error: getLocationError,
    }] = useLazyQuery(GET_LOCATION, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (get(getLocationData.getLocation, 'deactivated')) {
        setDeactivated(getLocationData.getLocation.deactivated);
      }
      if (get(getLocationData.getLocation, 'name')) {
        setName(getLocationData.getLocation.name);
      }
      if (get(getLocationData.getLocation, 'description')) {
        setDescription(getLocationData.getLocation.description);
      }
      if (get(getLocationData.getLocation, 'mailingAddress')) {
        if (get(getLocationData.getLocation.mailingAddress, 'address1')) {
          setAddress1(getLocationData.getLocation.mailingAddress.address1);
        }
        if (get(getLocationData.getLocation.mailingAddress, 'address2')) {
          setAddress2(getLocationData.getLocation.mailingAddress.address2);
        }
        if (get(getLocationData.getLocation.mailingAddress, 'city')) {
          setCity(getLocationData.getLocation.mailingAddress.city);
        }
        if (get(getLocationData.getLocation.mailingAddress, 'stateProvince')) {
          setStateProvince(getLocationData.getLocation.mailingAddress.stateProvince);
        }
        if (get(getLocationData.getLocation.mailingAddress, 'postalCode')) {
          setPostalCode(getLocationData.getLocation.mailingAddress.postalCode);
        }
        if (get(getLocationData.getLocation.mailingAddress, 'gpsCoordinates')) {
          setGPSCoordinates(getLocationData.getLocation.mailingAddress.gpsCoordinates);
        }
      }
      setModalOpen(true);
    },
  });

  if (getLocationLoading) return <div><Spin /></div>;
  if (getLocationError) return <div>{getLocationError}</div>;

  return (
    <div>
      <Button
        onClick={
          async (event) => {
            event.preventDefault();
            await doGetLocation({
              variables: { _id },
            });
          }
        }
        key={_id}
      >
        <EditOutlined />
      </Button>
      <Modal
        visible={modalOpen}
        destroyOnClose
        title={(
          <div>
            Update Location:&nbsp;
            {name}
          </div>
        )}
        okText="Submit"
        onCancel={() => setModalOpen(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doUpdateLocation({
            variables: {
              _id,
              deactivated,
              name,
              description,
              address1,
              address2,
              city,
              stateProvince,
              postalCode,
              gpsCoordinates,
            },
          });
          resetFormStates();
          setModalOpen(false);
        }}
        confirmLoading={updateLocationLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Address">
            <Input
              placeholder="Address 1"
              value={address1}
              onChange={event => setAddress1(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="Address 2"
              value={address2}
              onChange={event => setAddress2(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="City"
              value={city}
              onChange={event => setCity(event.target.value)}
              className="formItemGroupField"
            />
            <div className="formItemGroupField">
              <StateProvinceDropdown setStateProvince={setStateProvince} stateProvince={stateProvince} />
            </div>
            <Input
              placeholder="Postal Code"
              value={postalCode}
              onChange={event => setPostalCode(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="GPS Coordinates"
              value={gpsCoordinates}
              onChange={event => setGPSCoordinates(event.target.value)}
              className="formItemGroupField"
            />
          </Form.Item>
          <Form.Item layout="inline" name="deactivate" label="Deactivate">
            <Switch checked={deactivated} onChange={value => setDeactivated(value)} />
          </Form.Item>
          {updateLocationError && (
            <Alert
              message="There was an API error!"
              description={
                updateLocationError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

UpdateLocationModal.propTypes = {
  _id: PropTypes.string.isRequired,
};

export default UpdateLocationModal;
