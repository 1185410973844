import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import orderBy from 'lodash/orderBy';
import Cookies from 'js-cookie';
import { Alert, List, Spin } from 'antd';
import AddLocationModal from './AddLocationModal';
import UpdateLocationModal from './UpdateLocationModal';
import GET_USER_LOCATIONS from '../../graphql/locations/getUserLocations';

/*
  eslint
  no-undef: 0
*/

const LocationsList = () => {
  const currentUserId = Cookies.get('userId');

  const { loading, error, data } = useQuery(GET_USER_LOCATIONS,
    {
      variables: { ownerId: currentUserId },
    });

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
      >
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        message="There was an API error!"
        description={error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))}
        type="error"
        showIcon
      />
    );
  }

  const sortedLocations = orderBy(data.getUserLocations, ['name'], ['asc']);

  return (
    <List
      bordered
      header={(
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <strong style={{ marginRight: '1rem', fontSize: '2rem' }}>Location List</strong>
          <AddLocationModal />
        </div>
      )}
      loading={loading}
      itemLayout="horizontal"
      dataSource={sortedLocations}
      style={{ padding: '.5rem' }}
      renderItem={location => (
        <List.Item actions={[<UpdateLocationModal _id={location._id} key={location._id} />]}>
          <List.Item.Meta
            title={(
              <span>
                {location.name}
                <span style={{ color: 'red', padding: '0 .25rem' }}>
                  {(location.deactivated ? 'DEACTIVATED' : '')}
                </span>
              </span>
              )}
            description={location.description}
          />
        </List.Item>
      )}
    />
  );
};

export default LocationsList;
