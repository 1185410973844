import React, { useState } from 'react';
import { RocketOutlined } from '@ant-design/icons';
import { Alert, Spin, Steps, Tabs } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GET_USER_ONBOARDING_STATUS from '../../graphql/userAccount/getUserOnboardingStatus';

const { Step } = Steps;
const { TabPane } = Tabs;

const AccountFeed = (props) => {
  const [selectedTab, setSelectedTab] = useState('1');
  const { userId } = props;
  const { loading, error, data } = useQuery(GET_USER_ONBOARDING_STATUS,
    {
      variables: { _id: userId },
    });

  if (loading) return <div><Spin /></div>;

  if (error) {
    return (
      <Alert
        message="There was an API error!"
        description={error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))}
        type="error"
        showIcon
      />
    );
  }

  return (
    <Tabs defaultActiveKey="1" activeKey={selectedTab} onChange={activeKey => setSelectedTab(activeKey)}>
      <TabPane
        key="1"
        tab={(
          <span>
            <RocketOutlined />
            Onboarding
          </span>
        )}
      >
        <div>
          <Steps direction="vertical">
            <Step
              title={<Link to="/locations">Locations</Link>}
              description="Locations are where Inventories are physically located."
              status={(data.getUserOnboardingStatus.locations ? 'finish' : 'wait')}
            />
            <Step
              title={<Link to="/inventories">Inventories</Link>}
              description="Inventories are a collection of items stored at a Location."
              status={(data.getUserOnboardingStatus.inventories ? 'finish' : 'wait')}
            />
            <Step
              title={<Link to="/inventory-items-by-location">Inventory Items</Link>}
              description="Inventory Items belong to a specific Location's Inventory."
              status={(data.getUserOnboardingStatus.inventoryItems ? 'finish' : 'wait')}
            />
            <Step
              title={<Link to="/inventory-items-by-location">Inventory Item Quantity Updates</Link>}
              description="To properly keep track of your Inventory Items, periodically log current counts and update item quantities in order to help estimation accuracy."
              status={(data.getUserOnboardingStatus.inventoryItemCounts ? 'finish' : 'wait')}
            />
          </Steps>
        </div>
      </TabPane>
    </Tabs>
  );
};

AccountFeed.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default AccountFeed;
