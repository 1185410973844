import { gql } from 'apollo-boost';

const GET_LOCATION = gql`
  query getLocation($_id: String!) {
    getLocation(_id: $_id) {
      _id
      deactivated
      deleted
      name
      description
      mailingAddress {
        address1
        address2
        city
        postalCode
        stateProvince
        gpsCoordinates
      }
    }
  }
`;

export default GET_LOCATION;
