import React from 'react';
import { Alert, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import GET_CURRENT_USER from '../../graphql/userAccount/getCurrentUser';
import UserProfile from './UserProfile';
import AccountFeed from './AccountFeed';

/*
  eslint
  no-undef: 0
*/

const UserAccountInfo = () => {
  const currentUserId = Cookies.get('userId');

  const { loading, error, data } = useQuery(GET_CURRENT_USER,
    {
      variables: { _id: currentUserId },
    });

  if (loading) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <Alert
          message="There was an error!"
          description={error}
          type="error"
          showIcon
        />
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div style={{ width: 'auto', maxWidth: '600px', padding: '8px' }}>
        <UserProfile userInfo={data.getCurrentUser} />
      </div>
      <div style={{ width: 'auto', maxWidth: '600px', padding: '8px' }}>
        <AccountFeed userId={currentUserId} />
      </div>
    </div>
  );
};

export default UserAccountInfo;
