import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import { Alert, Button, Input, Form, Modal } from 'antd';
import CREATE_INVENTORY from '../../graphql/inventories/createInventory';
import GET_USER_INVENTORIES_WITH_LOCATION from '../../graphql/inventories/getUserInventoriesWithLocation';
import UserLocationsDropdown from '../core/UserLocationsDropdown';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const AddInventoryModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const currentUserId = Cookies.get('userId');

  const resetFormStates = () => {
    setLocationId('');
    setName('');
    setDescription('');
  }

  const [
    doCreateInventory,
    {
      // data,
      loading,
      error
    }
  ] = useMutation(CREATE_INVENTORY, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_USER_INVENTORIES_WITH_LOCATION, variables: { ownerId: currentUserId } }],
  });

  return (
    <div>
      <Button onClick={() => setOpenModal(true)}>Add Inventory</Button>
      <Modal
        visible={openModal}
        destroyOnClose
        title="Add Inventory"
        okText="Submit"
        onCancel={() => setOpenModal(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doCreateInventory({
            variables: {
              locationId,
              name,
              description,
            },
          });
          resetFormStates();
          setOpenModal(false);
        }}
        confirmLoading={loading}
      >
        <Form layout="vertical">
          <Form.Item label="Location">
            <UserLocationsDropdown locationId={locationId} setLocationId={setLocationId} />
          </Form.Item>
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          {error && (
            <Alert
              message="There was an API error!"
              description={
                error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AddInventoryModal;
