import React from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import GET_LOCATION_INVENTORIES from '../../graphql/inventories/getLocationInventories';

const { Option } = Select;

const getLocationInventoryList = (list) => {
  const sortedInventories = orderBy(list, ['name'], ['asc']);
  return sortedInventories.map(inventory => (
    <Option key={inventory._id} value={inventory._id} title={inventory.name}>{inventory.name}</Option>
  ));
};

const LocationInventoriesDropdown = (props) => {
  const { locationId, inventoryId, setInventoryId } = props;
  const { loading, error, data } = useQuery(GET_LOCATION_INVENTORIES,
    {
      variables: { locationId },
      fetchPolicy: 'cache-and-network',
    });

  if (error) return <div>{error}</div>;

  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={
        (input, option) => (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
      placeholder="Inventory"
      notFoundContent="No Inventories Found"
      value={inventoryId}
      onChange={(value) => { setInventoryId(value); }}
      loading={loading}
    >
      {data && getLocationInventoryList(data.getLocationInventories)}
    </Select>
  );
};

LocationInventoriesDropdown.propTypes = {
  locationId: PropTypes.string.isRequired,
  inventoryId: PropTypes.string,
  setInventoryId: PropTypes.func,
};

export default LocationInventoriesDropdown;
