import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { EditOutlined } from '@ant-design/icons';
import { Button, InputNumber, Switch, Modal, Input, Form, Alert, Spin } from 'antd';
import ItemCategoriesDropdown from './ItemCategoriesDropdown';
import GET_INVENTORY_ITEM from '../../graphql/inventoryItems/getInventoryItem';
import UPDATE_INVENTORY_ITEM from '../../graphql/inventoryItems/updateInventoryItem';
import GET_LOCATION_INVENTORIES_ITEMS from '../../graphql/inventoryItems/getLocationInventoriesItems';
import UserLocationsDropdown from '../core/UserLocationsDropdown';
import LocationInventoriesDropdown from '../core/LocationInventoriesDropdown';

/*
  eslint
  no-unused-vars: 0
*/

const UpdateInventoryItemModal = (props) => {
  const { _id, inventoryId, locationId } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [currentLocationId, setLocationId] = useState(locationId);
  const [currentInventoryId, setInventoryId] = useState(inventoryId);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minQuantity, setMinQuantity] = useState(0);

  const resetFormStates = () => {
    setDeactivated(false);
    setName('');
    setDescription('');
    setCategories([]);
    setMaxQuantity(0);
    setMinQuantity(0);
  }

  const [
    doUpdateInventoryItem,
    {
      // data: updateInventoryItemData,
      loading: updateInventoryItemLoading,
      error: updateInventoryItemError,
    },
  ] = useMutation(UPDATE_INVENTORY_ITEM, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_LOCATION_INVENTORIES_ITEMS, variables: { locationId: currentLocationId } }],
  });

  const [
    doGetInventoryItem,
    {
      data: getInventoryItemData,
      loading: getInventoryItemLoading,
      error: getInventoryItemError,
    },
  ] = useLazyQuery(GET_INVENTORY_ITEM, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (get(getInventoryItemData.getInventoryItem, 'deactivated')) {
        setDeactivated(getInventoryItemData.getInventoryItem.deactivated);
      }
      if (get(getInventoryItemData.getInventoryItem, 'name')) {
        setName(getInventoryItemData.getInventoryItem.name);
      }
      if (get(getInventoryItemData.getInventoryItem, 'description')) {
        setDescription(getInventoryItemData.getInventoryItem.description);
      }
      if (get(getInventoryItemData.getInventoryItem, 'categories')) {
        setCategories(getInventoryItemData.getInventoryItem.categories);
      }
      if (get(getInventoryItemData.getInventoryItem, 'maxQuantity')) {
        setMaxQuantity(getInventoryItemData.getInventoryItem.maxQuantity);
      }
      if (get(getInventoryItemData.getInventoryItem, 'minQuantity')) {
        setMinQuantity(getInventoryItemData.getInventoryItem.minQuantity);
      }
      setModalOpen(true);
    },
  });

  if (getInventoryItemLoading) return <div><Spin /></div>;
  if (getInventoryItemError) return <div>{getInventoryItemError}</div>;

  return (
    <div>
      <Button
        onClick={
          async (event) => {
            event.preventDefault();
            await doGetInventoryItem({
              variables: { _id },
            });
          }
        }
        key={_id}
        style={{ margin: '.25rem' }}
      >
        <EditOutlined />
      </Button>
      <Modal
        visible={modalOpen}
        destroyOnClose
        title={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              Update Inventory Item:&nbsp;
              {name}
            </div>
          </div>
        )}
        okText="Submit"
        onCancel={() => setModalOpen(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doUpdateInventoryItem({
            variables: {
              _id,
              inventoryId: currentInventoryId,
              name,
              description,
              categories,
              maxQuantity,
              minQuantity,
              deactivated,
            },
          });
          resetFormStates();
          setModalOpen(false);
        }}
        confirmLoading={updateInventoryItemLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Location">
            <UserLocationsDropdown
              locationId={currentLocationId}
              setLocationId={setLocationId}
              setInventoryId={setInventoryId}
            />
          </Form.Item>
          <Form.Item label="Inventory">
            <LocationInventoriesDropdown
              locationId={currentLocationId}
              inventoryId={currentInventoryId}
              setInventoryId={setInventoryId}
            />
          </Form.Item>
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Categories">
            <ItemCategoriesDropdown selectedCategories={categories} setCategories={setCategories} />
          </Form.Item>
          <Form.Item label="Max Quantity">
            <InputNumber
              min={0}
              max={1000000000000}
              step={0.001}
              value={maxQuantity}
              onChange={value => setMaxQuantity(value)}
            />
          </Form.Item>
          <Form.Item label="Min Quantity">
            <InputNumber
              min={0}
              max={1000000000000}
              step={0.001}
              value={minQuantity}
              onChange={value => setMinQuantity(value)}
            />
          </Form.Item>
          <Form.Item label="Deactivate">
            <Switch checked={deactivated} onChange={value => setDeactivated(value)} />
          </Form.Item>
          {updateInventoryItemError && (
            <Alert
              message="There was an API error!"
              description={
                updateInventoryItemError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

UpdateInventoryItemModal.propTypes = {
  _id: PropTypes.string.isRequired,
  inventoryId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
};

export default UpdateInventoryItemModal;
