import React, { useState } from 'react';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Spin, Alert, Form, notification } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import EARLY_ADOPTER_SIGNUP from '../../graphql/earlyAdopters/earlyAdopterSignup';
import * as logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const EarlyAdopterSignupForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "You're on the List!",
      description: "You have successfully signed up for our early adopter mailing list. When we're ready we'll send you an invite to join our Beta program.",
    });
  };

  const [
    doEarlyAdopterSignup,
    {
      // data,
      loading,
      error,
    },
  ] = useMutation(EARLY_ADOPTER_SIGNUP, {
    onCompleted: () => {
      openNotificationWithIcon('success');
    },
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '100%', maxWidth: '480px', padding: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="logo" style={{ maxHeight: '150px', marginBottom: '2rem' }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>Request an invite and become an early adopter!</div>
        </div>
        <Form
          onFinish={async (event) => {
            await doEarlyAdopterSignup({
              variables: {
                email,
                name,
              },
            });
          }}
          className="early-adopter-signup-form"
        >
          <Form.Item validateStatus={!name && 'warning'}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item validateStatus={!email && 'warning'}>
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" className="early-adopter-signup-form-button" loading={loading}>
              Sign Up
            </Button>
            <div>
              Already have an acccount?&nbsp;
              <Link to="/login">Login</Link>
            </div>
          </Form.Item>
          {error && (
            <Alert
              message="There was an API error!"
              description={
                error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default EarlyAdopterSignupForm;
