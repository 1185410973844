import { gql } from 'apollo-boost';

const GET_USER_INVENTORIES_WITH_LOCATION = gql`
  query getUserInventoriesWithLocation($ownerId: String!) {
    getUserInventoriesWithLocation(ownerId: $ownerId) {
      _id
      deactivated
      name
      description
      location {
        _id
        name
      }
    }
  }
`;

export default GET_USER_INVENTORIES_WITH_LOCATION;
