import { gql } from 'apollo-boost';

const GET_LOCATION_INVENTORIES = gql`
  query getLocationInventories($locationId: String!) {
    getLocationInventories(locationId: $locationId) {
      _id
      name
      description
      locationId
    }
  }
`;

export default GET_LOCATION_INVENTORIES;
