import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { Input, Button, Alert, Form } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import LOGIN from '../../graphql/userAccount/login';
import * as logo from '../../assets/logo.png';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const LoginForm = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [
    doLogin,
    {
      data: loginData,
      loading: loginLoading,
      error: loginError,
    },
  ] = useMutation(LOGIN, {
    onCompleted: ({ login }) => {
      Cookies.set('token', login.token, { expires: 7 });
      Cookies.set('userId', login._id, { expires: 7 });
      props.history.push('/account');
    },
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '100%', maxWidth: '480px', padding: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="logo" style={{ maxHeight: '150px', marginBottom: '2rem' }} />
        </div>
        <Form
          onFinish={async (event) => {
            await doLogin({
              variables: {
                username,
                password,
              },
            });
          }}
          className="login-form"
        >
          <Form.Item>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              value={username}
              onChange={event => setUsername(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" className="login-form-button" loading={loginLoading}>
              Log in
            </Button>
            <div>
              <div>
                Or&nbsp;
                <Link to="/sign-up">sign up now!</Link>
              </div>
            </div>
          </Form.Item>
          {loginError && (
            <Alert
              message="There was an API error!"
              description={loginError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(LoginForm);
