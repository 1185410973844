import React from 'react';
import { Drawer, Typography, List } from 'antd';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import * as logo from '../../assets/quinListCart.png';

const MenuDrawer = (props) => {
  const { drawerOpen, setDrawerOpen, history } = props;
  return (
    <Drawer
      title={(
        <div>
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <img src={logo} alt="logo" style={{ maxHeight: '50px', width: 'auto', padding: '.125rem .5rem .125rem 0' }} />
            <Typography.Text>QuinList</Typography.Text>
          </div>
        </div>
      )}
      placement="left"
      closable={false}
      visible={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <List>
        <List.Item as="a" key="2" onClick={() => history.push('/login')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Login</Typography.Text>
        </List.Item>
        <List.Item as="a" key="3" onClick={() => history.push('/early-adopter')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Request Beta Access</Typography.Text>
        </List.Item>
      </List>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(MenuDrawer);
