import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { Button, Switch, Modal, Input, Form, Alert } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import UserLocationsDropdown from '../core/UserLocationsDropdown';
import GET_INVENTORY from '../../graphql/inventories/getInventory';
import UPDATE_INVENTORY from '../../graphql/inventories/updateInventory';
import GET_USER_INVENTORIES from '../../graphql/inventories/getUserInventories';
import GET_USER_INVENTORIES_WITH_LOCATION from "../../graphql/inventories/getUserInventoriesWithLocation";

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const UpdateInventoryModal = (props) => {
  const { _id } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [deactivated, setDeactivated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const currentUserId = Cookies.get('userId');

  const resetFormStates = () => {
    setLocationId(null);
    setDeactivated(false);
    setName('');
    setDescription('');
  }

  const [
    doUpdateInventory,
    {
      // data: updateInventoryData,
      loading: updateInventoryLoading,
      error: updateInventoryError,
    },
  ] = useMutation(UPDATE_INVENTORY, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_USER_INVENTORIES_WITH_LOCATION, variables: { ownerId: currentUserId } }],
  });

  const [doGetInventory, { data: getInventoryData }] = useLazyQuery(GET_INVENTORY, {
    onCompleted: () => {
      if (get(getInventoryData.getInventory, 'locationId')) {
        setLocationId(getInventoryData.getInventory.locationId);
      }
      if (get(getInventoryData.getInventory, 'deactivated')) {
        setDeactivated(getInventoryData.getInventory.deactivated);
      }
      if (get(getInventoryData.getInventory, 'name')) {
        setName(getInventoryData.getInventory.name);
      }
      if (get(getInventoryData.getInventory, 'description')) {
        setDescription(getInventoryData.getInventory.description);
      }
    },
  });

  return (
    <div>
      <Button
        onClick={
          async (event) => {
            event.preventDefault();
            await doGetInventory({
              variables: { _id },
            });
            setModalOpen(true);
          }
        }
        key={_id}
      >
        <EditOutlined />
      </Button>
      <Modal
        visible={modalOpen}
        destroyOnClose
        title={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              Update&nbsp;Inventory:&nbsp;
              {name}
            </div>
          </div>
        )}
        okText="Submit"
        onCancel={() => setModalOpen(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doUpdateInventory({
            variables: {
              _id,
              deactivated,
              locationId,
              name,
              description,
            },
          });
          resetFormStates();
          setModalOpen(false);
        }}
        confirmLoading={updateInventoryLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Location">
            <UserLocationsDropdown locationId={locationId} setLocationId={setLocationId} />
          </Form.Item>
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Deactivate">
            <Switch checked={deactivated} onChange={value => setDeactivated(value)} />
          </Form.Item>
          {updateInventoryError && (
            <Alert
              message="There was an API error!"
              description={
                updateInventoryError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

UpdateInventoryModal.propTypes = {
  _id: PropTypes.string.isRequired,
};

export default UpdateInventoryModal;
