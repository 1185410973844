import { gql } from 'apollo-boost';

const EARLY_ADOPTER_SIGNUP = gql`
  mutation earlyAdopterSignup($email: String!, $name: String!) {
    earlyAdopterSignup(input: {email: $email, name: $name}) {
      _id
      email
      name
    }
  }
`;

export default EARLY_ADOPTER_SIGNUP;
