import { gql } from 'apollo-boost';

const GET_LOCATION_INVENTORIES_ITEMS = gql`
  query getLocationInventoriesItems($locationId: String!) {
    getLocationInventoriesItems(locationId: $locationId) {
      inventories {
        _id
        deactivated
        name
      }
      inventoryItems {
        _id
        deactivated
        inventoryId
        inventoryName
        currentQuantity
        stockoutDate
        name
        description
        categories
        maxQuantity
        minQuantity
        associatedItemIds
      }
    }
  }
`;

export default GET_LOCATION_INVENTORIES_ITEMS;
