import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { List, Alert, Spin } from 'antd';
import Cookies from 'js-cookie';
import orderBy from 'lodash/orderBy';
import GET_USER_INVENTORIES_WITH_LOCATION from '../../graphql/inventories/getUserInventoriesWithLocation';
import AddInventoryModal from './AddInventoryModal';
import UpdateInventoryModal from './UpdateInventoryModal';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const InventoriesList = (props) => {
  // const { setSelectedInventory, setSelectedInventoryName } = props;
  const currentUserId = Cookies.get('userId');

  const { loading, error, data } = useQuery(GET_USER_INVENTORIES_WITH_LOCATION, {
    variables: { ownerId: currentUserId },
  });

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
      >
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <Alert
        message="There was an API error!"
        description={error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))}
        type="error"
        showIcon
      />
    );
  }
  const sortedInventories = orderBy(data.getUserInventoriesWithLocation, ['name'], ['asc']);

  return (
    <List
      bordered
      header={(
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <strong style={{ marginRight: '1rem', fontSize: '2rem' }}>Inventory List</strong>
          <AddInventoryModal />
        </div>
      )}
      loading={loading}
      dataSource={sortedInventories}
      style={{ padding: '.5rem' }}
      renderItem={inventory => (
        <List.Item actions={[<UpdateInventoryModal _id={inventory._id} key={inventory._id} />]}>
          <List.Item.Meta
            title={(
              <div>
                {inventory.name}
                <span>
                  &nbsp;at&nbsp;
                  <span>{inventory.location.name}</span>
                </span>
                &nbsp;
                <span style={{ color: 'red', padding: '0 .25rem' }}>{(inventory.deactivated ? 'DEACTIVATED' : '')}</span>
              </div>
            )}
            description={inventory.description}
          />
        </List.Item>
      )}
    />
  );
};

// InventoryLocation.propTypes = {
//   _id: PropTypes.string.isRequired,
// };

// InventoriesList.propTypes = {
//   setSelectedInventory: PropTypes.func.isRequired,
//   setSelectedInventoryName: PropTypes.func.isRequired,
// };

export default InventoriesList;
