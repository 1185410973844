import { gql } from 'apollo-boost';

const CREATE_INVENTORY_ITEM_QUANTITY_UPDATE = gql`
  mutation createInventoryItemQuantityUpdate (
    $inventoryItemId: String!,
    $currentQuantityCountedDate: Date,
    $currentQuantityCounted: Float,
    $upcCounted: String,
    $quantityAddedDate: Date,
    $quantityAdded: Float,
    $upcAdded: String,
    $stockoutDate: Date,
  ) {
    createInventoryItemQuantityUpdate(
      input: {
        inventoryItemId: $inventoryItemId,
        currentQuantityCountedDate: $currentQuantityCountedDate,
        currentQuantityCounted: $currentQuantityCounted,
        upcCounted: $upcCounted,
        quantityAddedDate: $quantityAddedDate,
        quantityAdded: $quantityAdded,
        upcAdded: $upcAdded,
        stockoutDate: $stockoutDate,
      }
    ) {
      inventoryItemAdd,
      inventoryItemCount
    }
  }
`;

export default CREATE_INVENTORY_ITEM_QUANTITY_UPDATE;
