import { gql } from 'apollo-boost';

const CREATE_INVENTORY_ITEM = gql`
  mutation createInventoryItem (
    $inventoryId: String!,
    $name: String!,
    $description: String,
    $categories: [String],
    $upc: String,
    $currentQuantity: Float,
    $stockoutDate: Date,
    $maxQuantity: Float,
    $minQuantity: Float
  ) {
    createInventoryItem(
      input: {
        inventoryId: $inventoryId,
        name: $name,
        description: $description,
        categories: $categories,
        upc: $upc,
        currentQuantity: $currentQuantity,
        stockoutDate: $stockoutDate,
        maxQuantity: $maxQuantity,
        minQuantity: $minQuantity
      }
    ) {
      _id
      inventoryId
      associatedItemIds
      name
      description
      categories
      maxQuantity
      minQuantity
    }
  }
`;

export default CREATE_INVENTORY_ITEM;
