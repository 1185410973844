import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import CREATE_LOCATION from '../../graphql/locations/createLocation';
import GET_USER_LOCATIONS from '../../graphql/locations/getUserLocations';
import StateProvinceDropdown from '../core/StateProvinceDropdown';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const AddLocationModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState(undefined);
  const [postalCode, setPostalCode] = useState('');
  const [gpsCoordinates, setGPSCoordinates] = useState('');
  const currentUserId = Cookies.get('userId');

  const resetFormStates = () => {
     setName('');
     setDescription('');
     setAddress1('');
     setAddress2('');
     setCity('');
     setStateProvince(undefined);
     setPostalCode('');
     setGPSCoordinates('');
  }

  const [
    doCreateLocation,
    {
      // data: createLocationData,
      loading: createLocationLoading,
      error: createLocationError,
    }
  ] = useMutation(CREATE_LOCATION, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_USER_LOCATIONS, variables: { ownerId: currentUserId } }],
  });

  return (
    <div>
      <Button onClick={() => setModalOpen(true)}>Add Location</Button>
      <Modal
        visible={modalOpen}
        destroyOnClose
        title="Add Location"
        okText="Submit"
        onCancel={() => setModalOpen(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doCreateLocation({
            variables: {
              name,
              description,
              address1,
              address2,
              city,
              stateProvince,
              postalCode,
              gpsCoordinates,
            },
          });
          resetFormStates();
          setModalOpen(false);
        }}
        confirmLoading={createLocationLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Address">
            <Input
              placeholder="Address 1"
              value={address1}
              onChange={event => setAddress1(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="Address 2"
              value={address2}
              onChange={event => setAddress2(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="City"
              value={city}
              onChange={event => setCity(event.target.value)}
              className="formItemGroupField"
            />
            <div className="formItemGroupField">
              <StateProvinceDropdown setStateProvince={setStateProvince} stateProvince={stateProvince} />
            </div>
            <Input
              placeholder="Postal Code"
              value={postalCode}
              onChange={event => setPostalCode(event.target.value)}
              className="formItemGroupField"
            />
            <Input
              placeholder="GPS Coordinates"
              value={gpsCoordinates}
              onChange={event => setGPSCoordinates(event.target.value)}
              className="formItemGroupField"
            />
          </Form.Item>
          {createLocationError && (
            <Alert
              message="There was an API error!"
              description={
                // eslint-disable-next-line react/no-array-index-key
                createLocationError.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AddLocationModal;
