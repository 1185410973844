import React from 'react';
import SiteLayout from '../components/core/SiteLayout';
import { hasAuthToken } from '../App';
import EarlyAdopterSignupForm from '../components/marketing/EarlyAdopterSignupForm';

const EarlyAdopterSignup = () => {
  return (
    <div>
      <SiteLayout isLoggedIn={hasAuthToken()}>
        <div className="flex-centered-stack">
          <div className="framing-container-lg">
            <EarlyAdopterSignupForm />
          </div>
        </div>
      </SiteLayout>
    </div>
  );
};

export default EarlyAdopterSignup;
