import React from 'react';
import SiteLayout from '../components/core/SiteLayout';
import UserAccountInfo from '../components/userAccount/UserAccountInfo';

const Account = () => (
  <div>
    <SiteLayout isLoggedIn>
      <div className="flex-centered-stack">
        <div className="framing-container-lg">
          <UserAccountInfo />
        </div>
      </div>
    </SiteLayout>
  </div>
);

export default Account;
