import { gql } from 'apollo-boost';

const UPDATE_INVENTORY = gql`
  mutation updateInventory ($_id: String!, $locationId: String!, $deactivated: Boolean, $name: String!, $description: String) {
    updateInventory(
      input: {
        _id: $_id,
        locationId: $locationId,
        deactivated: $deactivated,
        name: $name,
        description: $description,
      }
    ) {
      _id
      locationId
      deactivated
      name
      description
    }
  }
`;

export default UPDATE_INVENTORY;
