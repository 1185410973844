import { gql } from 'apollo-boost';

const GET_INVENTORY = gql`
  query getInventory($_id: String!) {
    getInventory(_id: $_id) {
      _id
      deactivated
      name
      description
      locationId
    }
  }
`;

export default GET_INVENTORY;
