import React from 'react';
import { withRouter } from 'react-router';
import SiteLayout from '../components/core/SiteLayout';
import SignUpForm from '../components/userAccount/SignUpForm';

const SignUp = () => (
  <SiteLayout>
    <div className="flex-centered-stack">
      <div>
        <SignUpForm />
      </div>
    </div>
  </SiteLayout>
);

export default withRouter(SignUp);
