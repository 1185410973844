import React from 'react';
import { withRouter } from 'react-router';
import SiteLayout from '../components/core/SiteLayout';
import LocationTabs from '../components/inventoryItems/inventoryItemsByLocation/LocationTabs';

const InventoryItemsByLocation = () => (
  <SiteLayout isLoggedIn>
    <div className="flex-centered-stack">
      <div className="framing-container-lg">
        <LocationTabs />
      </div>
    </div>
  </SiteLayout>
);

export default withRouter(InventoryItemsByLocation);
