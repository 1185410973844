import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Alert, Button, DatePicker, Input, InputNumber, Form, Modal } from 'antd';
import CREATE_INVENTORY_ITEM from '../../graphql/inventoryItems/createInventoryItem';
import GET_LOCATION_INVENTORIES_ITEMS from '../../graphql/inventoryItems/getLocationInventoriesItems';
import ItemCategoriesDropdown from './ItemCategoriesDropdown';
import UserLocationsDropdown from '../core/UserLocationsDropdown';
import LocationInventoriesDropdown from '../core/LocationInventoriesDropdown';

/*
  eslint
  no-undef: 0
  no-unused-vars: 0
*/

const AddInventoryItemModal = (props) => {
  const { locationId, inventoryId } = props;
  const [openModal, setOpenModal] = useState(false);
  const [currentLocationId, setLocationId] = useState(locationId);
  const [currentInventoryId, setInventoryId] = useState(inventoryId);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [upc, setUPC] = useState('');
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [stockoutDate, setStockoutDate] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minQuantity, setMinQuantity] = useState(0);

  const resetFormStates = () => {
    setName('');
    setDescription('');
    setCategories([]);
    setUPC('');
    setCurrentQuantity(0);
    setStockoutDate(null);
    setMaxQuantity(0);
    setMinQuantity(0);
  }

  const [
    doCreateInventoryItem,
    {
      // data,
      loading,
      error
    },
  ] = useMutation(CREATE_INVENTORY_ITEM, {
    awaitRefetchQueries: false,
    refetchQueries: [{ query: GET_LOCATION_INVENTORIES_ITEMS, variables: { locationId: currentLocationId } }],
  });

  return (
    <div>
      <Button onClick={() => setOpenModal(true)}>Add Inventory Item</Button>
      <Modal
        visible={openModal}
        destroyOnClose
        title="Add Inventory Item"
        okText="Submit"
        onCancel={() => setOpenModal(false)}
        onOk={async (event) => {
          event.preventDefault();
          await doCreateInventoryItem({
            variables: {
              inventoryId: currentInventoryId,
              name,
              description,
              categories,
              upc,
              currentQuantity,
              stockoutDate,
              maxQuantity,
              minQuantity,
            },
          });
          resetFormStates();
          setOpenModal(false);
        }}
        confirmLoading={loading}
      >
        <Form layout="vertical">
          <Form.Item label="Location">
            <UserLocationsDropdown
              locationId={currentLocationId}
              setLocationId={setLocationId}
              setInventoryId={setInventoryId}
            />
          </Form.Item>
          <Form.Item label="Inventory">
            <LocationInventoriesDropdown
              locationId={currentLocationId}
              inventoryId={currentInventoryId}
              setInventoryId={setInventoryId}
            />
          </Form.Item>
          <Form.Item label="Name">
            <Input
              placeholder="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Categories">
            <ItemCategoriesDropdown selectedCategories={categories} setCategories={setCategories} />
          </Form.Item>
          <Form.Item label="UPC">
            <Input
              placeholder="UPC"
              value={upc}
              onChange={event => setUPC(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Current Quantity">
            <InputNumber
              min={0}
              max={1000000000000}
              step={0.001}
              value={currentQuantity}
              onChange={value => setCurrentQuantity(value)}
            />
          </Form.Item>
          <Form.Item label="Estimated Out of Stock or Expiration Date">
            <DatePicker
              required
              showTime
              placeholder="Select Time"
              onChange={value => setStockoutDate(value)}
            />
          </Form.Item>
          <Form.Item label="Max Quantity">
            <InputNumber
              min={0}
              max={1000000000000}
              step={0.001}
              value={maxQuantity}
              onChange={value => setMaxQuantity(value)}
            />
          </Form.Item>
          <Form.Item label="Min Quantity">
            <InputNumber
              min={0}
              max={1000000000000}
              step={0.001}
              value={minQuantity}
              onChange={value => setMinQuantity(value)}
            />
          </Form.Item>
          {error && (
            <Alert
              message="There was an API error!"
              description={
                error.graphQLErrors.map(({ message }, i) => (<div key={i}>{message}</div>))
              }
              type="error"
              showIcon
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

AddInventoryItemModal.propTypes = {
  locationId: PropTypes.string.isRequired,
  inventoryId: PropTypes.string,
};

export default AddInventoryItemModal;
