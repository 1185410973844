import { gql } from 'apollo-boost';

const UPDATE_LOCATION = gql`
  mutation updateLocation(
    $_id: String!,
    $deactivated: Boolean,
    $name: String!,
    $description: String,
    $address1: String,
    $address2: String,
    $city: String,
    $stateProvince: String,
    $postalCode: String,
    $gpsCoordinates: String) {
    updateLocation(
      input: {
        _id: $_id,
        deactivated: $deactivated,
        name: $name,
        description: $description,
        mailingAddress: {
          address1: $address1,
          address2: $address2,
          city: $city,
          stateProvince: $stateProvince,
          postalCode: $postalCode,
          gpsCoordinates: $gpsCoordinates
        },
      }
    ) {
      _id
      deactivated
      name
      description
      mailingAddress {
        address1
        address2
        city
        stateProvince
        postalCode
        gpsCoordinates
      }
    }
  }
`;

export default UPDATE_LOCATION;
