import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import AuthMenuDrawer from './AuthMenuDrawer';
import MenuDrawer from './MenuDrawer';
import '../../index.css';
import 'antd/dist/antd.css';

const { Header, Content, Footer } = Layout;

/*
  eslint
  no-undef: 0
*/

const SiteLayout = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn, children } = props;

  let currentPath = '/';
  try {
    if (window.location.href) {
      currentPath = window.location.href;
    }
  } catch (e) {}

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>QuinList: Your Quintessential Shopping List</title>
        <meta name="description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        <link rel="canonical" href={currentPath} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="QuinList" />
        <meta property="og:title" content="QuinList: Your Quintessential Shopping List" />
        <meta property="og:description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        {/* <meta property="og:image" content={`${currentOrigin}${image}`} /> */}
        {/* <meta property="og:url" content={currentPath} /> */}
        <meta name="twitter:title" content="QuinList: Your Quintessential Shopping List" />
        <meta name="twitter:description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        {/* <meta name="twitter:image" content={`${currentOrigin}${image}`} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:image:alt" content={imgAlt} /> */}
      </Helmet>
      <Layout className="pageContainer" role="main">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0 }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: '64px' }}
            selectable={false}
          >
            <Menu.Item as="a" key="0" onClick={() => setDrawerOpen(true)}>
              <MenuOutlined />
            </Menu.Item>
          </Menu>
        </Header>
        {isLoggedIn ? (
          <AuthMenuDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        ) : (
          <MenuDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        )}
        <Content style={{ background: '#fff', padding: '0 50px', marginTop: 64 }} className="siteContent">
          {children}
        </Content>
        <Footer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap' }} className="siteFooter">
          <div className="py-2" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Link to="/about">About Us</Link>
            &nbsp;|&nbsp;
            <Link to="/contact">Contact Us</Link>
            &nbsp;|&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
            &nbsp;|&nbsp;
            <Link to="/terms">Terms of Service</Link>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            Copyright&nbsp;&copy;
            {new Date().getFullYear()}
            &nbsp;QuinList by&nbsp;
            <a href="https://metztech.co">Metz Tech Co</a>
            &nbsp;All Rights Reserved
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

SiteLayout.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default withRouter(SiteLayout);
