import { gql } from 'apollo-boost';

const GET_INVENTORY_ITEM = gql`
  query getInventoryItem($_id: String!) {
    getInventoryItem(_id: $_id) {
      _id
      deactivated
      inventoryId
      name
      description
      categories
      maxQuantity
      minQuantity
      associatedItemIds
    }
  }
`;

export default GET_INVENTORY_ITEM;
