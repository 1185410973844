import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Tabs } from 'antd';
import Cookies from 'js-cookie';
import sortBy from 'lodash/sortBy';
import { useQuery } from '@apollo/react-hooks';
import LocationInventoryItemsTab from './LocationInventoryItemsTab';
import GET_USER_LOCATIONS from '../../../graphql/locations/getUserLocations';

/*
  eslint
  no-undef: 0
*/

const { TabPane } = Tabs;

const getLocationPanes = (list) => {
  const sortedLocations = sortBy(list, 'name');
  const locations = sortedLocations.map((location, index) => (
    <TabPane
      key={index}
      tab={location.name}
    >
      <LocationInventoryItemsTab locationId={location._id} />
    </TabPane>
  ));
  return locations;
};

const LocationTabs = () => {
  const [activeTab, setActiveTab] = useState('0');
  const currentUserId = Cookies.get('userId');
  const { loading, error, data } = useQuery(GET_USER_LOCATIONS,
    {
      variables: { ownerId: currentUserId },
    });

  if (loading) return <div><Spin /></div>;
  if (error) return <div>{error}</div>;

  if (data.getUserLocations.length > 0) {
    return (
      <Tabs activeKey={activeTab} onTabClick={key => setActiveTab(key)}>
        {getLocationPanes(data.getUserLocations)}
      </Tabs>
    );
  }
  return (
    <div>
      <Link to="/locations">Please Create a Location</Link>
    </div>
  );
};

export default LocationTabs;
