import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const OPTIONS = [
  'Household', 'Cleaning', 'Health', 'Beauty', 'Office Supplies', 'Food',
];

const ItemCategoriesDropdown = (props) => {
  const { disabled, selectedCategories, setCategories } = props;
  const filteredOptions = OPTIONS.filter(o => !selectedCategories.includes(o));
  return (
    <Select
      mode="multiple"
      placeholder="Categorize your item"
      value={selectedCategories}
      onChange={value => setCategories(value)}
      style={{ width: '100%' }}
      disabled={disabled}
    >
      {filteredOptions.map(item => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

ItemCategoriesDropdown.propTypes = {
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCategories: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ItemCategoriesDropdown;
