import React from 'react';
import { Typography } from 'antd';
import SiteLayout from '../components/core/SiteLayout';
import { hasAuthToken } from '../App';

const { Title, Paragraph, Link } = Typography;

const Contact = () => (
  <div>
    <SiteLayout isLoggedIn={hasAuthToken()}>
      <div className="flex-centered-stack">
        <div className="framing-container-md">
          <Title>Contact Us</Title>
          <Paragraph>
            Please contact us if you have any questions about the QuinList application or have an interest in a technical integration!
          </Paragraph>
          <Title level={2}>Support</Title>
          <Paragraph>
            <Link href="mailto:support@quinlist.app">support@quinlist.app</Link>
          </Paragraph>
        </div>
      </div>
    </SiteLayout>
  </div>
);

export default Contact;
