import { gql } from 'apollo-boost';

const SIGNUP = gql`
  mutation signup($email: String!, $username: String!, $password: String!, $name: String!, $mobilePhone: String) {
    signup(input: {email: $email, username: $username, password: $password, name: $name, mobilePhone: $mobilePhone}) {
      _id
      username
      token
    }
  }
`;

export default SIGNUP;
