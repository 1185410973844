import React from 'react';
import { Typography } from 'antd';
import SiteLayout from '../components/core/SiteLayout';
import { hasAuthToken } from '../App';

const { Title, Paragraph, Link } = Typography;

const About = () => (
  <div>
    <SiteLayout isLoggedIn={hasAuthToken()}>
      <div className="flex-centered-stack">
        <div className="framing-container-md">
          <Title>About Us</Title>
          <Paragraph>
            QuinList allows you to optimize your shopping with smart inventory management and an always up to date shopping list. With our app you can access a shopping list of your quintessential items at any time.
          </Paragraph>
          <Paragraph>
            QuinList allows lets you create any number of inventories and locations. Through periodic item counting, our algorithm helps predict your item quantities. Easily filter your items to better see which items are running low.
          </Paragraph>
          <Paragraph>
            QuinList is owned and operated by <Link href='https://metztech.co'>Metz Tech Co</Link> out of California, USA.
          </Paragraph>
        </div>
      </div>
    </SiteLayout>
  </div>
);

export default About;
