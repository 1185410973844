import React/* , { useState } */ from 'react';
import PropTypes from 'prop-types';
import { Table, Tag } from 'antd';
import InventoryItemButtons from './InventoryItemButtons';

const InventoryItemsByLocationTable = (props) => {
  const { items, inventories, locationId, loading } = props;
  // const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});

  // const clearFilters = () => {
  //   setFilteredInfo({});
  // }
  //
  // const clearAll = () => {
  //   setFilteredInfo({});
  //   setSortedInfo({});
  // }
  //
  // const handleChange = (pagination, filters, sorter) => {
  //   setSortedInfo(filters);
  //   setSortedInfo(sorter);
  // }

  const getCategoriesForFilter = (list) => {
    const allCategories = [];
    list.forEach(item => allCategories.push(...item.categories));
    const uniqueCategories = [...new Set(allCategories)];
    return uniqueCategories.map(category => ({ text: category, value: category }));
  };

  const getInventoriesForFilter = (list) => {
    const allInventories = [];
    for (let i = 0; i < list.length; i++) {
      allInventories.push({ text: list[i].name, value: list[i].name });
    }
    return allInventories;
  };

  const categoriesForFilter = getCategoriesForFilter(items);
  const inventoriesForFilter = getInventoriesForFilter(inventories);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    }, {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      filterMultiple: true,
      filters: categoriesForFilter,
      // filteredValue: filteredInfo.categories || null,
      onFilter: (value, record) => record.categories.includes(value),
      render: categories => (
        <span>
          {categories.map(category => <Tag key={category}>{category}</Tag>)}
        </span>
      ),
    }, {
      title: 'Inventory',
      key: 'inventory',
      dataIndex: 'inventory',
      sorter: (a, b) => a.inventoryName.length - b.inventoryName.length,
      sortDirections: ['descend', 'ascend'],
      filterMultiple: true,
      filters: inventoriesForFilter,
      // filteredValue: filteredInfo.inventory || null,
      onFilter: (value, record) => record.inventoryName.includes(value),
      render: (text, record) => <span>{record.inventoryName}</span>,
    }, {
      title: 'Current Quantity',
      key: 'currentQuantity',
      dataIndex: 'currentQuantity',
      sorter: (a, b) => a.currentQuantity - b.currentQuantity,
      sortDirections: ['descend', 'ascend'],
      render: (number, record) => <span>{record.currentQuantity}</span>,
    }, {
      title: 'Stockout Date',
      key: 'stockoutDate',
      dataIndex: 'stockoutDate',
      sorter: (a, b) => new Date(a.stockoutDate) - new Date(b.stockoutDate),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (number, record) => (
        <span>{record.stockoutDate}</span>
      ),
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterMultiple: false,
      filters: [{text: 'ACTIVE', value: false}, {text: 'DEACTIVATED', value: true}],
      onFilter: (value, record) => {
        if (record.deactivated === value) {
          return true;
        } else {
          return false;
        }
      },
      render: (text, record) => (
          <span style={{ color: (record.deactivated ? 'red' : 'green') }}>
            {(record.deactivated ? 'DEACTIVATED' : 'ACTIVE')}
          </span>
        ),
    }, {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <InventoryItemButtons
          _id={record._id}
          locationId={locationId}
          inventoryId={record.inventoryId}
          name={record.name}
        />
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={items}
      size="small"
      ellipsis
      // expandedRowRender={
      //   record => (
      //     <div />
      //   )
      // }
      loading={loading}
      rowKey={record => record._id}
    />
  );
};

InventoryItemsByLocationTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventories: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InventoryItemsByLocationTable;
