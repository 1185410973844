import React from 'react';
import PropTypes from 'prop-types';
import * as logo from '../../assets/logo.png';

const UserProfile = (props) => {
  const { userInfo } = props;
  return (
    <div>
      <div>
        <img src={logo} alt="logo" style={{ maxHeight: '150px', marginBottom: '1rem' }} />
        <h1>
          Hello&nbsp;
          {userInfo.name}
          ,
          welcome to QuinList!
        </h1>
        <p>
          QuinList is designed to help optimize your shopping with inventory management and an always up to date shopping list.
          The app allows you to create any number of inventories and locations.
          Through periodic item counting our algorithm helps predict your item quantities allowing you to shop smarter.
          We are your quintessential shopping list app!
          <br />
        </p>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default UserProfile;
