import React from 'react';
import { Drawer, Typography, List } from 'antd';
import { withRouter } from 'react-router';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import * as logo from '../../assets/quinListCart.png';

const AuthMenuDrawer = (props) => {
  const { drawerOpen, setDrawerOpen, history } = props;
  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('userId');
    history.push('/login');
  };
  return (
    <Drawer
      title={(
        <div>
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <img src={logo} alt="logo" style={{ maxHeight: '50px', width: 'auto', padding: '.125rem .5rem .125rem 0' }} />
            <Typography.Text>QuinList</Typography.Text>
          </div>
        </div>
      )}
      placement="left"
      closable={false}
      visible={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <List>
        <List.Item as="a" key="1" onClick={() => history.push('/account')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Dashboard</Typography.Text>
        </List.Item>
        <List.Item as="a" key="2" onClick={() => history.push('/locations')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Locations</Typography.Text>
        </List.Item>
        <List.Item as="a" key="3" onClick={() => history.push('/inventories')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Inventories</Typography.Text>
        </List.Item>
        <List.Item as="a" key="4" onClick={() => history.push('/inventory-items-by-location')} className="drawerItem">
          <Typography.Text style={{ paddingLeft: '.5rem' }}>Inventory Items by Location</Typography.Text>
        </List.Item>
        {/* <List.Item as="a" key="5" onClick={() => history.push('/types-and-categories')} className="drawerItem"> */}
        {/*  <Typography.Text style={{ paddingLeft: '.5rem' }}>Item Types and Categories</Typography.Text> */}
        {/* </List.Item> */}
        {/* <List.Item as="a" key="6" onClick={() => history.push('/vendors')} className="drawerItem"> */}
        {/*  <Typography.Text style={{ paddingLeft: '.5rem' }}>Vendors</Typography.Text> */}
        {/* </List.Item> */}
        <List.Item as="a" key="7" onClick={() => logout()} className="drawerItem">
          <span style={{ paddingLeft: '.5rem' }} className="nav-text">Logout</span>
        </List.Item>
      </List>
    </Drawer>
  );
};

AuthMenuDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(AuthMenuDrawer);
