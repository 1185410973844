import { gql } from 'apollo-boost';

const UPDATE_INVENTORY_ITEM = gql`
  mutation updateInventoryItem(
    $_id: String!,
    $deactivated: Boolean,
    $inventoryId: String!,
#    $associatedItemIds: [String],
    $name: String!,
    $description: String,
    $categories: [String],
    $maxQuantity: Float,
    $minQuantity: Float,
    ) {
    updateInventoryItem(
      input: {
        _id: $_id,
        deactivated: $deactivated,
        inventoryId: $inventoryId,
#        associatedItemIds: $associatedItemIds,
        name: $name,
        description: $description,
        categories: $categories,
        maxQuantity: $maxQuantity,
        minQuantity: $minQuantity,
      }
    ) {
      _id
      deactivated
      inventoryId
      associatedItemIds
      name
      description
      categories
      maxQuantity
      minQuantity
    }
  }
`;

export default UPDATE_INVENTORY_ITEM;
