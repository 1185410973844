import { gql } from 'apollo-boost';

const CREATE_INVENTORY = gql`
  mutation createInventory($locationId: String!, $name: String!, $description: String) {
    createInventory(input: {locationId: $locationId, name: $name, description: $description}) {
      _id
      locationId
      name
      description
    }
  }
`;

export default CREATE_INVENTORY;
