import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* eslint no-undef: 0 */
render(<App />, document.getElementById('root'));

serviceWorker.unregister();
