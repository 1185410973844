import { gql } from 'apollo-boost';

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!,
    $description: String,
    $address1: String,
    $address2: String,
    $city: String,
    $stateProvince: String,
    $postalCode: String,
    $gpsCoordinates: String
    ) {
    createLocation(
      input: {
        name: $name
        description: $description
        mailingAddress: {
          address1: $address1
          address2: $address2
          city: $city
          stateProvince: $stateProvince
          postalCode: $postalCode
          gpsCoordinates: $gpsCoordinates
        }
      }
    ) {
      _id
      ownerType
      ownerId
      name
      description
      mailingAddress {
        address1
        address2
        city
        stateProvince
        postalCode
        gpsCoordinates
      }
    }
  }
`;

export default CREATE_LOCATION;
