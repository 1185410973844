import jwt from 'jsonwebtoken';
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Login from './layouts/Login';
import LandingPage from './layouts/LandingPage';
import About from './layouts/About';
import Contact from './layouts/Contact';
import EarlyAdopterSignup from './layouts/EarlyAdopterSignup';
import SignUp from './layouts/SignUp';
import Account from './layouts/Account';
import Locations from './layouts/Locations';
import Inventories from './layouts/Inventories';
import InventoryItemsByLocation from './layouts/InventoryItemsByLocation';
import PrivacyPolicy from './layouts/PrivacyPolicy';
import TermsOfService from './layouts/TermsOfService';
import './styles.less';

/*
  eslint
  no-undef: 0
  react/prop-types: 0
*/

// const api = 'http://127.0.0.1:3000/dev/graphql'; /* process.env.REACT_APP_API_URI || */
// const api = 'https://gksdejfgk8.execute-api.us-west-2.amazonaws.com/dev/graphql';
const api = 'https://asqtl87fe3.execute-api.us-west-2.amazonaws.com/prod/graphql';

const client = new ApolloClient({
  uri: api,
  request: async (operation) => {
    const token = Cookies.get('token');
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
});

export const hasAuthToken = () => {
  const token = Cookies.get('token');
  if (!token) {
    return false;
  }
  const decodedToken = jwt.decode(token, (err) => { if (err) { return false; } });
  if (!decodedToken) { return false; }
  return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (hasAuthToken() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    ))}
  />
);

const App = () => (
  <ApolloProvider client={client}>
    <Router forceRefresh>
      <div className="App">
        <Route exact path="/" component={LandingPage} />
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/early-adopter" component={EarlyAdopterSignup} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/terms" component={TermsOfService} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/locations" component={Locations} />
        <PrivateRoute path="/inventories" component={Inventories} />
        <PrivateRoute path="/inventory-items-by-location" component={InventoryItemsByLocation} />
      </div>
    </Router>
  </ApolloProvider>
);

export default App;
