import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import requestAccess from '../assets/requestBetaAccess.png';
import bg from '../assets/background.png';

const LandingPage = () => {
  const style = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      alignItems: 'end',
      background: `url(${bg}) #c2fff3`,
      minHeight: '100vh',
      height: '100%',
    },
  };

  let currentPath = '/';
  try {
    if (window.location.href) {
      currentPath = window.location.href;
    }
  } catch (e) {}

  return (
    <div style={style.mainContainer}>
      <Helmet>
        <html lang="en" />
        <title>QuinList: Your Quintessential Shopping List</title>
        <meta name="description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        <link rel="canonical" href={currentPath} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="QuinList" />
        <meta property="og:title" content="QuinList: Your Quintessential Shopping List" />
        <meta property="og:description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        {/* <meta property="og:image" content={`${currentOrigin}${image}`} /> */}
        {/* <meta property="og:url" content={currentPath} /> */}
        <meta name="twitter:title" content="QuinList: Your Quintessential Shopping List" />
        <meta name="twitter:description" content="QuinList is an inventory and shopping list management app for helping anyone track their items and inventories." />
        {/* <meta name="twitter:image" content={`${currentOrigin}${image}`} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:image:alt" content={imgAlt} /> */}
      </Helmet>
      <div
        role="main"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <div>
          <Link to="/early-adopter">
            <img src={requestAccess} alt="requestBetaAccess" className="requestBetaAccess" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LandingPage);
