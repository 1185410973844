import React from 'react';
import SiteLayout from '../components/core/SiteLayout';
import LoginForm from '../components/userAccount/LoginForm';

const Login = () => (
  <SiteLayout isLoggedIn={false}>
    <div className="flex-centered-stack">
      <div>
        <LoginForm />
      </div>
    </div>
  </SiteLayout>
);

export default Login;
