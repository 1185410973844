import React from 'react';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import AddInventoryItemModal from '../AddInventoryItemModal';
import InventoryItemsByLocationTable from './InventoryItemsByLocationTable';
import GET_LOCATION_INVENTORIES_ITEMS from '../../../graphql/inventoryItems/getLocationInventoriesItems';
import { Spin } from 'antd';

const LocationInventoryItemsTab = (props) => {
  const { locationId } = props;
  const { loading, error, data } = useQuery(GET_LOCATION_INVENTORIES_ITEMS,
    {
      variables: { locationId },
    });

  if (loading) return <div><Spin /></div>;
  if (error) return <div>{error}</div>;

  const items = sortBy(data.getLocationInventoriesItems.inventoryItems, 'name');
  const { inventories } = data.getLocationInventoriesItems;

  if (inventories.length > 0) {
    return (
      <div>
        <div style={{ marginBottom: '.5rem' }}><AddInventoryItemModal locationId={locationId} /></div>
        <InventoryItemsByLocationTable items={items} inventories={inventories} locationId={locationId} loading={loading} />
      </div>
    );
  }

  return (
    <div>
      <Link to="/inventories">Please Create an Inventory for this Location</Link>
    </div>
  );
};

LocationInventoryItemsTab.propTypes = {
  locationId: PropTypes.string.isRequired,
};

export default LocationInventoryItemsTab;
